// const axios = require("axios")
// const BaseURL = "https://api.rosearcher.xyz"
// const packageJson = require("./package.json");
// const clc = require("cli-color")



// async function GetAPIStatus() {
//     try {
//         const Response = await axios.get(BaseURL);
//         if (Response && Response.status === 200) {
//             return "Online"
//         }  
//     } catch (error) {
//         throw error;
//     }
// }

// async function checkForUpdates() {
//     const npmRegistryURL = `https://registry.npmjs.org/${packageJson.name}/latest`;
//     const latestVersion = (await axios.get(npmRegistryURL)).data.version;
//     if (latestVersion !== packageJson.version) {
//         console.warn(clc.yellowBright(`[WARNING]`), clc.whiteBright(`Your version (${packageJson.version}) of this package is outdated. Latest version is ${latestVersion}.`));
//     } else {
//         console.log(`Rosearcher Is Update to-date Current Version: ${latestVersion}`)
//     }

//     if (packageJson.version > latestVersion) {
//         console.warn(clc.redBright(`[INVAILD VERSION]]`), `(${packageJson.version}) is Invaild the Newest Version is (${latestVersion})`)
//     }
// }

// checkForUpdates()



// module.exports = GetAPIStatus;

import axios from "https://cdn.skypack.dev/axios";
import { readFileSync } from 'https://cdn.skypack.dev/fs-';
import clc from "https://cdn.skypack.dev/cli-color";

const BaseURL = "https://api.rosearcher.xyz";

async function GetAPIStatus() {
    try {
        const Response = await axios.get(BaseURL);
        if (Response && Response.status === 200) {
            return "Online";
        }  
    } catch (error) {
        throw error;
    }
}

async function checkForUpdates() {
    const packageJson = JSON.parse(readFileSync('./package.json', 'utf-8'));
    const npmRegistryURL = `https://registry.npmjs.org/${packageJson.name}/latest`;
    const latestVersion = (await axios.get(npmRegistryURL)).data.version;
    if (latestVersion !== packageJson.version) {
        console.warn(clc.yellowBright(`[WARNING]`), clc.whiteBright(`Your version (${packageJson.version}) of this package is outdated. Latest version is ${latestVersion}.`));
    } else {
        console.log(`Rosearcher Is Up-to-date. Current Version: ${latestVersion}`);
    }

    if (packageJson.version > latestVersion) {
        console.warn(clc.redBright(`[INVALID VERSION]]`), `(${packageJson.version}) is Invalid. The Newest Version is (${latestVersion})`);
    }
}

checkForUpdates();

export { GetAPIStatus };
